<template>
  <el-dialog
      :visible.sync="loginDialog"
      :close-on-click-modal="false"
      center>

    <div class="register">
      <div class="title">FreeCode少儿编程助学平台</div>
      <div class="tab">
        <div><span>登录</span></div>
        <div @click="$emit('showRegister')">注册</div>
      </div>
      <ul>
        <li><img src="../../assets/images/member/shoujihao@2x.png"> <input type="tel" placeholder="请输入手机号码" v-model="form.mobile"></li>
        <li>
          <img src="../../assets/images/member/mima@2x.png">
          <input :type="passwordType" placeholder="请输入密码" v-model="form.password">
          <img src="../../assets/images/member/yincang@2x.png" v-if="passwordType === 'password'" @click="showPasswordInput('text')">
          <img src="../../assets/images/member/xianshi@2x.png" v-if="passwordType === 'text'" @click="showPasswordInput('password')">
        </li>
      </ul>
      <div class="password">
        <div>没有账号？ <span @click="$emit('showRegister')">免费注册</span></div>
        <div @click="$emit('showPassword')">忘记密码？</div>
      </div>
      <el-button type="primary" class="button" :loading="isSubmit" @click="submitForm">登 录</el-button>
    </div>

  </el-dialog>
</template>

<script>
import Cookie from "js-cookie";
import userApi from "@/api/userApi";

export default {
  name: 'LoginPage',
  data () {
    return {
      loginDialog: false,
      form: {
        mobile: '', // 手机号
        password: '' // 密码
      },
      isSubmit: false,
      passwordType: 'password'
    }
  },
  methods: {
    show() {
      this.loginDialog = true
    },
    hide() {
      this.loginDialog = false
    },
    showRegister() {
      this.$emit('showUserDialog', 'register')
    },
    showPassword() {
      this.$emit('showUserDialog', 'password')
    },
    showPasswordInput(val) {
      this.passwordType = val
    },
    async submitForm() {
      if (this.isCheck) {
        return ;
      }
      if (!this.form.mobile) {
        return this.$message({ message: '请正确的输入手机号', type: 'error'})
      }
      if (!this.form.password) {
        return this.$message({ message: '请输入密码', type: 'error'})
      }
      this.isSubmit = true

      const { data: res } = await userApi.mobileLogin(this.form)
      this.isSubmit = false;
      console.log(res)
      console.log("------")
      if (res.code === 0) {
        return this.$message({ message: res.msg, type: 'error'})
      } else {
        Cookie.set('token', res.data.token);
        const userinfo = JSON.stringify(res.data.user)
        Cookie.set('userinfo', userinfo);
        if(Cookie.get('userinfo') && Cookie.get('token')){
          this.$message({ message: '登录成功', type: 'success'})
          // this.$router.go(0)
          window.location.reload();
          // this.$router.push({ path: '/' });
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  width: 534px;
  height: 496px;
  background: url("../../assets/images/member/dltc_bg@2x.png") no-repeat center center;
  background-size: 100% 100%;
  box-shadow: none;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: #3D3D3D;
}
::v-deep .el-dialog__headerbtn {
  top: 40px;
  right: 25px;
  font-size: 20px;
}
::v-deep .el-dialog__header {
  padding: 0;
}

.register {
  width: 320px;
  margin: 0 auto;
  .title {
    margin-top: 70px;
    font-weight: 700;
    font-size: 22px;
    color: #5D68FE;
    text-align: center;
  }
  .tab {
    width: 100%;
    height: 90px;
    line-height: 90px;
    div {
      width: 50%;
      float: left;
      text-align: center;
      font-weight: 700;
      font-size: 14px;
      color: #3D3D3D;
      cursor: pointer;
      span {
        border-bottom: 2px solid #5D68FE;
      }
    }
  }
  .button {
    width: 320px;
    height: 50px;
    background: #5D68FE;
    border-radius: 10px 10px 10px 10px;
    border: 0;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    margin-top: 20px;
    cursor: pointer;
  }
  .password {
    width: 320px;
    height: 30px;
    line-height: 30px;
    font-weight: 400;
    font-size: 12px;
    color: #C1C1C8;
    div {
      width: 50%;
      span {
        font-weight: 400;
        font-size: 12px;
        color: #5D68FE;
        cursor: pointer;
      }
    }
    div:nth-child(1) {
      float: left;
    }
    div:nth-child(2) {
      float: right;
      text-align: right;
      cursor: pointer;
    }
  }
  ul {
    width: 320px;
    margin: 0 auto;
    height: auto;
    overflow: hidden;
    li {
      height: 50px;
      background: #FFFFFF;
      border-radius: 10px 10px 10px 10px;
      border: 1px solid #E4E4E4;
      margin-bottom: 20px;
      clear: both;
      input {
        width: 218px;
        height: 50px;
        margin: 0;
        padding: 0;
        float: left;
        border: 0;
      }
      input:focus {
        outline: none;
        outline-width: 0;
      }
      img {
        width: 16px;
        height: 16px;
        float: left;
        margin: 17px 17px;
        cursor: pointer;
      }
    }
    li:nth-child(2) {
      margin-bottom: 0;
    }
  }



}

</style>
