// 路由器
import VueRouter from 'vue-router'
import Cookie from 'js-cookie'

const whiteList = [
    '/', '/courses', '/course/detail', '/course/video',
    '/exam', '/exam/question', '/news', '/news/detail',
    '/member', '/member/school', '/member/agreement', '/member/points', '/member/pay',

]// no redirect whitelist

const router = new VueRouter({
  mode: 'history',
  base:'/web/',
  routes: [
    {
      path: '/',
      component: () => import('@/views/index/index'),
      name: 'index',
    },
    {
      path: '/exam',
      name: 'exam',
      component: () => import('../views/exam/index.vue'),
    },
    {
      path: '/exam/question',
      name: 'examQuestion',
      component: () => import('../views/exam/question.vue'),
    },
    {
      path: '/exam/question/detail',
      name: 'examQuestionDetail',
      component: () => import('../views/exam/detail.vue'),
    },
    {
      path: '/news',
      name: 'news',
      component: () => import('../views/news/index.vue'),
    },
    {
      path: '/news/detail',
      name: 'newDetail',
      component: () => import('../views/news/detail.vue'),
    },
    {
      path: '/member',
      name: 'memberMember',
      component: () => import('@/views/member/member.vue'),
    },
    {
      path: '/member/school',
      name: 'memberSchool',
      component: () => import('@/views/member/school.vue'),
    },
    {
      path: '/member/agreement',
      name: 'memberAgreement',
      component: () => import('@/views/member/agreement.vue'),
    },
    {
      path: '/member/points',
      name: 'memberPoints',
      component: () => import('@/views/member/points.vue'),
    },
    {
      path: '/member/pay',
      name: 'memberPay',
      component: () => import('@/views/member/pay.vue'),
    },
    {
      path: '/answer',
      name: 'answer',
      component: () => import('../views/exam/answer'),
    },
    {
      path: '/paper',
      name: 'paper',
      component: () => import('../views/exam/paper'),
    },
    {
      path: '/test',
      name: 'test',
      component: () => import('../views/exam/test'),
    },
    {
      path: '/train',
      name: 'train',
      component: () => import('../views/train/index'),
    },
    {
      path: '/statistics',
      name: 'statistics',
      component: () => import('../views/statistics/index'),
    },
    {
      path: '/center',
      name: 'center',
      component: () => import('../views/center/index'),
    },
    {
      path: '/center/exam',
      name: 'centerExam',
      component: () => import('../views/center/exam'),
    },
    {
      path: '/center/wrong',
      name: 'centerWrong',
      component: () => import('../views/center/wrong'),
    },
    {
      path: '/wrongDetail',
      name: 'wrongDetail',
      component: () => import('../views/center/wrongDetail'),
    },
    {
      path: '/center/collect',
      name: 'centerCollect',
      component: () => import('../views/center/collect'),
    },
    {
      path: '/collectDetail',
      name: 'collectDetail',
      component: () => import('../views/center/collectDetail'),
    },
    {
      path: '/center/course',
      name: 'centerCourse',
      component: () => import('../views/center/course'),
    },
    {
      path: '/center/order',
      name: 'centerOrder',
      component: () => import('../views/center/order'),
    },
    {
      path: '/courses',
      name: 'courseIndex',
      component: () => import('../views/course/index'),
    },
    {
      path: '/course/detail',
      name: 'courseDetail',
      component: () => import('../views/course/detail'),
    },
    {
      path: '/course/video',
      name: 'courseVideo',
      component: () => import('../views/course/video'),
    },
    {
      path: '/*',
      name: '404',
      component: () => import('../views/404'),
    },
  ],
})

// to到哪去， from从哪来，next执行下一步
router.beforeEach((to, from, next) => {
  const token = Cookie.get('token')
  console.log("token",token)
  if (whiteList.indexOf(to.path) !== -1) { // 在免登录白名单，直接进入
    next()
  } else if (!token && to.name !== 'login') { // 未登录时，以及未登录访问其他页面时，跳转到login页面
    // 未登录时，调用全局方法显示登录弹窗
    // window.vm.$refs.login.show()
    next(false) // 阻止路由跳转
    setTimeout(() => {
      if (window.vm && window.vm.$refs.login) {
        window.vm.$refs.login.show()
      }
    }, 0)
    // next({ name: 'login' })
  } else if (token && to.name === 'login') { // 当已经登录，还打开了登录页面，直接跳转到对应的内容页面首页
    next({ name: 'home' })
  } else { // 在这里，过滤下，看是否有权限能访问
    next()
  }
  next()
})

// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)  //修改原型对象中的push方法,解决重复刷新报错的问题
}

export default router
