// 适配 Nginx 反向代理
const baseUrl = process.env.VUE_APP_API_URL === '/' ? '' : process.env.VUE_APP_API_URL
const baseFileUrl = process.env.VUE_APP_FILE_URL === '/' ? '' : process.env.VUE_APP_FILE_URL
const api = {
  state: {
    // baseUrl，
    baseApi: baseUrl,
    imageUrl: baseFileUrl
  }
}

export default api
