import request from "@/utils/request";

export default {
    resetpwd(data){
        return request({
            method: 'POST',
            url: '/api/user/resetpwd',
            data
        })
    },
    register(data){
        return request({
            method: 'POST',
            url: '/addons/exam/user/register',
            data
        })
    },
    captcha(){
        return request({
            method: 'GET',
            url: '/api/sms/captcha'
        })
    },
    send(data){
        return request({
            method: 'POST',
            url: '/api/sms/send',
            data: data
        })
    },
    userInfo(){
        return request({
            method: 'POST',
            url: '/addons/exam/user/info'
        })
    },
    mobileLogin(data){
        return request({
            method: 'POST',
            url: '/addons/exam/user/mobileLogin',
            data
        })
    },
    userLogin(data){
        return request({
            method: 'POST',
            url: '/addons/exam/user/userLogin',
            data
        })
    },
    userEdit(data){
        return request({
            method: 'POST',
            url: '/addons/exam/user/save',
            data
        })
    },
    notice(data){
        return request({
            method: 'POST',
            url: '/addons/exam/user/notice',
            data
        })
    },
    look(data){
        return request({
            method: 'POST',
            url: '/addons/exam/user/look',
            data
        })
    },
    buyCourse(data){
        return request({
            method: 'POST',
            url: '/api/course/buy',
            data
        })
    },
    grade(params){
        return request({
            method: 'GET',
            url: '/addons/exam/user/grade',
            params
        })
    },
    deleteGrade(data) {
        return request({
            method: 'POST',
            url: '/addons/exam/user/deleteGrade',
            data
        })
    }
}

