<template>
  <div id="app">
    <router-view></router-view>
    <Login ref="login" @showRegister="showRegister" @showPassword="showPassword"/>
    <Register ref="register" @showLogin="showLogin"/>
    <Password ref="password" @showLogin="showLogin"/>
  </div>
</template>

<script>
import Login from "@/components/common/login.vue";
import Register from "@/components/common/register.vue";
import Password from "@/components/common/password.vue";

export default {
  name: 'App',
  components: {Password, Register, Login},
  data () {
    return {
    }
  },
  mounted() {
    window.vm = this
  },
  methods: {
    showRegister() {
      this.$refs.login.hide()
      this.$refs.register.show()
    },
    showPassword() {
      this.$refs.login.hide()
      this.$refs.password.show()
    },
    showLogin() {
      this.$refs.register.hide()
      this.$refs.password.hide()
      this.$refs.login.show()
    }
  }
}
</script>

<style lang="scss">
@import url("@/assets/css/public.css");
.wrap{
  width: 100%;
  min-height: 800px;
  font-size: 14px;
  margin: 0 auto;
}
</style>
