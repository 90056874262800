<template>
  <el-dialog
      :visible.sync="passwordDialog"
      :close-on-click-modal="false"
      center>

    <div class="register">
      <div class="title">FreeCode少儿编程助学平台</div>
      <ul>
        <li><img src="../../assets/images/member/shoujihao@2x.png"> <input type="tel" v-model="form.mobile" placeholder="请输入手机号码"></li>
        <li>
          <img src="../../assets/images/member/yanzheng@2x.png">
          <input type="text" v-model="form.captcha" class="code" placeholder="请输入手机验证码">
          <span class="icon">|</span>
          <span v-if="showText" @click="getCode()" style="cursor: pointer">发送验证码</span>
          <span v-else>重新发送({{ second }})</span>
        </li>
        <li>
          <img src="../../assets/images/member/mima@2x.png">
          <input :type="passwordType" placeholder="请输入密码" v-model="form.password">
          <img src="../../assets/images/member/yincang@2x.png" v-if="passwordType === 'password'" @click="showPasswordInput('text')">
          <img src="../../assets/images/member/xianshi@2x.png" v-if="passwordType === 'text'" @click="showPasswordInput('password')">
        </li>
        <li>
          <img src="../../assets/images/member/mima@2x.png">
          <input :type="passwordType" placeholder="请再次输入密码" v-model="form.repassword">
          <img src="../../assets/images/member/yincang@2x.png" v-if="passwordType === 'password'" @click="showPasswordInput('text')">
          <img src="../../assets/images/member/xianshi@2x.png" v-if="passwordType === 'text'" @click="showPasswordInput('password')">
        </li>
      </ul>

      <el-button class="button" type="primary" :loading="isSubmit" @click="submitForm">重 置</el-button>
    </div>

  </el-dialog>
</template>

<script>
  import userApi from "@/api/userApi";

  export default {
    name: 'PasswordPage',
    data () {
      return {
        passwordDialog: false,
        form: {
          mobile: '', // 手机号
          password: '', // 密码
          repassword: '', // 密码
          captcha: ''
        },
        isSubmit: false,
        passwordType: 'password',
        showText: true,
        second: 60,
      }
    },
    methods: {
      show() {
        this.passwordDialog = true
      },
      hide() {
        this.passwordDialog = false
      },
      showPasswordInput(val) {
        this.passwordType = val
      },
      async submitForm() {
        if (this.isCheck) {
          return ;
        }
        if (!this.form.mobile) {
          return this.$message({ message: '请正确的输入手机号', type: 'error'})
        }
        if (!this.form.captcha) {
          return this.$message({ message: '请输入手机验证码', type: 'error'})
        }
        if (!this.form.password) {
          return this.$message({ message: '请输入密码', type: 'error'})
        }
        if (this.form.password.length < 6 || this.form.password.length > 20) {
          return this.$message({ message: '密码长度必须在6-20位之间', type: 'error'})
        }
        if (this.form.password !== this.form.repassword) {
          return this.$message({ message: '两次密码不一致', type: 'error'})
        }
        this.isSubmit = true

        const { data: result } = await userApi.resetpwd(this.form)
        this.isSubmit = false;
        if (result.code === 0) {
          return this.$message({ message: result.msg, type: 'error'})
        } else {
          this.$message({ message: '重置密码成功', type: 'success'})
          window.location.reload();
        }
      },
      async getCode() {
        var that = this;
        if (that.isSubmit) {
          return;
        }
        if (that.form.mobile.length === 0) {
          that.$message.error({
            message: '请输入手机号',
            duration: 1500
          })
          return;
        }
        if (!/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(that.form.mobile)) {
          that.$message.error({
            message: '请输入正确手机号',
            duration: 1500
          })
          return;
        }

        that.isSubmit = true
        const { data } = await userApi.send({ mobile: that.form.mobile, event: 'resetpwd' })
        that.isSubmit = false
        if (data.code === 0) {
          that.$message.error({
            message: data.msg,
            duration: 1500
          })
          return
        }

        var interval = setInterval(() => {
          that.showText = false
          //that.second = times<10?'0'+times:times ;//小于10秒补 0
          that.second = that.second - 1
        }, 1000);

        setTimeout(() => {
          clearInterval(interval);
          that.second = 60;
          that.showText = true
        }, 60000)
      }
    }
  }
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  width: 534px;
  height: 496px;
  background: url("../../assets/images/member/dltc_bg@2x.png") no-repeat center center;
  background-size: 100% 100%;
  box-shadow: none;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: #3D3D3D;
}
::v-deep .el-dialog__headerbtn {
  top: 40px;
  right: 25px;
  font-size: 20px;
}
::v-deep .el-dialog__header {
  padding: 0;
}

.register {
  width: 320px;
  margin: 0 auto;
  .title {
    margin-top: 50px;
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 22px;
    color: #5D68FE;
    text-align: center;
  }
  .button {
    width: 320px;
    height: 50px;
    background: #5D68FE;
    border-radius: 10px 10px 10px 10px;
    border: 0;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
  }
  ul {
    width: 320px;
    margin: 0 auto;
    height: auto;
    overflow: hidden;
    li {
      height: 50px;
      background: #FFFFFF;
      border-radius: 10px 10px 10px 10px;
      border: 1px solid #E4E4E4;
      margin-bottom: 20px;
      clear: both;
      input {
        width: 218px;
        height: 50px;
        margin: 0;
        padding: 0;
        float: left;
        border: 0;
      }
      input:focus {
        outline: none;
        outline-width: 0;
      }
      input.captcha {
        width: 170px;
        margin-left: 20px;
      }
      input.code {
        width: 160px;
      }
      span.icon {
        float: left;
        width: 2px;
        color: #D8D8D8;
      }
      span {
        display: block;
        float: left;
        width: 100px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        color: #5D68FE;
      }
      img {
        width: 16px;
        height: 16px;
        float: left;
        margin: 17px 17px;
        cursor: pointer;
      }
      img.captcha {
        width: 110px;
        height: 50px;
        margin: 0;
        float: right;
        border-radius: 10px 10px 0 0;
        cursor: pointer;
      }
    }
  }



}

</style>
