import request from "@/utils/request";

export default {
    articleCategory() {
        return request({
            method: 'GET',
            url: '/api/article/category'
        })
    },
    articleList(params) {
        return request({
            method: 'GET',
            url: '/api/article/lists',
            params
        })
    },
    articleDetail(params) {
        return request({
            method: 'GET',
            url: '/api/article/detail',
            params
        })
    },
    dictCategory(params) {
        return request({
            method: 'GET',
            url: '/api/dict/category',
            params
        })
    },
    dictSubject(params) {
        return request({
            method: 'GET',
            url: '/api/dict/subject',
            params
        })
    },
    dictLevel(params) {
        return request({
            method: 'GET',
            url: '/api/dict/level',
            params
        })
    },
}
