import axios from "axios";
import Cookie from 'js-cookie'

const request = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 20000,
    withCredentials: false, // 跨域请求的时候是否需要凭证
    headers: {
        'Content-Type': 'application/json;charset=utf-8'
    },
})


request.interceptors.request.use( // request拦截器
    config => {
        let contentType = config.headers['Content-Type'];
        if(contentType){
            config.headers['Content-Type']= contentType
        }
        // console.log(config.method, config.url, config.data, 'config')
        // if(config.url == 'phoneCallLog/excelExport'){
        //     request.defaults.responseType = 'blob'
        // }

        // let token =  Cookie.get('token') ? Cookie.get('token') : null
        let token =  Cookie.get('token') ? Cookie.get('token') : null
        if (token) {
            config.headers['TOKEN'] = token // 让每个请求携带自定义token 请根据实际情况自行修改
        }
        return config;
    },
    error => {
        return Promise.reject(error); // 请求错误处理
    }
)

request.interceptors.response.use( // 过滤器
    response => {
        // console.log(response, 'axios lanjie') // 这里查询鉴权功能
        // if (response.data.errno === 999) {
        //     this.$router.push('/login')
        //     console.log("token过期");
        // }
        return response;
    },
    error => {
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    Cookie.set('token', '');
                    Cookie.set('userinfo', '');
                    // router.push({ path: 'login' });
                    // 未登录或 Token 失效，显示登录弹窗
                    window.vm.$refs.login.show()
                    break;
                case 404:
                    console.log('Resource not found');
                    break;
                case 500:
                    console.log('There is a server problem');
                    break;
                default:
                    console.log('There is an error');
                    console.log(error);
            }
        }
        return Promise.reject(error);
    }
);

export default request
